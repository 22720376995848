import App from "./App";
import { ThemeProvider } from "react-jss";
import theme from "./theme/jssTheme";
import { ConfigProvider } from "antd";

export default function Root() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
          colorText: "#201A1E",
          fontFamily: "'Karla', sans-serif",
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfigProvider>
  );
}
