import { Typography } from "antd";
import Flex from "components/Flex";
import { createUseStyles } from "react-jss";
import QrCode from "assets/enk-qr.png";

export default function QRPage() {
  const classes = useStyle();

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      className={classes.qrPage}
    >
      <Typography.Text className={classes.pageTitle}>
        RING SIZER - Edelsteinfasser Nawin Kumar
      </Typography.Text>
      <Typography.Text className={classes.pageSubTitle}>
        To find your perfect ring size please visit <br />{" "}
        <a href="https://ringsizer.edelsteinfasser-kumar.de/">
          ringsizer.edelsteinfasser-kumar.de
        </a>{" "}
        on your phone or scan the QR code below.
      </Typography.Text>
      <img src={QrCode} alt="" className={classes.qrCode} />
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  qrPage: {
    textAlign: "center",
    height: "90vh",
    padding: [0, "2.5rem"],
  },
  qrCode: {
    marginTop: "2rem",
    width: 220,
  },
  pageTitle: {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    fontWeight: 600,
    letterSpacing: ".05em",
  },
  pageSubTitle: {
    maxWidth: 450,
    marginTop: "0.5rem",
    fontSize: "1rem",
    "& a": {
      color: "#505050",
      textDecoration: "underline",
    },
  },
}));
