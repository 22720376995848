import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Button, Typography } from "antd";
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import CustomSlider from "components/Slider";
import Flex from "components/Flex";

import ringSizes from "data/ring-sizes.json";

interface Step4Props {
  onBack: () => void;
  onNext: () => void;
}

export default function Step4Type1({ onBack, onNext }: Step4Props) {
  const classes = useStyle();
  const ring = useRef<HTMLDivElement>(null);
  const [pixelToMmRatio, setPixelToMmRatio] = useState(0);
  const [sliderValue, setSliderValue] = useState(49);
  const [scale, setScale] = useState(1);
  const [ringSize, setRingSize] = useState(6.8);
  const [germanRingSize, setGermanRingSize] = useState("");
  const [ringX, setRingX] = useState(0);
  const [ringY, setRingY] = useState(0);

  const moveRing = (dir: "u" | "r" | "d" | "l") => () => {
    switch (dir) {
      case "u":
        setRingY((curr) => curr - 1);
        break;
      case "r":
        setRingX((curr) => curr + 1);
        break;
      case "d":
        setRingY((curr) => curr + 1);
        break;
      case "l":
        setRingX((curr) => curr - 1);
        break;
    }
  };

  const handleNext = () => {
    localStorage.setItem("ringSize", germanRingSize);
    onNext();
  };

  useEffect(() => {
    let value = localStorage.getItem("px2mm");
    if (!!value) {
      setPixelToMmRatio(+value);
    }
  }, [localStorage.getItem("px2mm")]);

  useEffect(() => {
    if (pixelToMmRatio) {
      const ringDiam =
        ring!!.current!!.getBoundingClientRect().bottom -
        ring!!.current!!.getBoundingClientRect().top;
      const mm = (ringDiam / pixelToMmRatio).toFixed(2);

      const _germanRingSize =
        ringSizes.sort((a, b) => b.mm - a.mm).find((size) => +mm > size.mm)
          ?.size ?? `Kleiner als 40`;

      setGermanRingSize("" + _germanRingSize);
      setScale(0.6 + 0.008 * sliderValue);
      setRingSize(+mm);
    }
  }, [sliderValue, pixelToMmRatio]);

  return (
    <Flex
      direction="column"
      justify="space-between"
      className={classes.stepWrapper}
    >
      <Flex justify="space-between">
        <Button
          icon={<LeftOutlined />}
          type="text"
          className={classes.goBackBtn}
          onClick={onBack}
        >
          Zurück
        </Button>
      </Flex>

      <div className={classes.instructionText}>
        Lege deinen Ring auf den Kreis und richte die schwarze Umrandung mit der
        Innenseite deines Ringes aus.
      </div>

      <Flex justify="center" align="center" className={classes.ringWrapper}>
        <div
          ref={ring}
          className={classes.ring}
          style={{
            transform: `translate(${ringX}px, ${ringY}px) scale(${scale})`,
          }}
        >
          <span className={classes.ringSize}>{ringSize.toFixed(2)}mm</span>
        </div>

        <Flex className={classes.arrowsWrapper}>
          <Button
            type="text"
            icon={<UpOutlined />}
            onClick={moveRing("u")}
            className={classes.directionButton}
            style={{
              top: "-25px",
              left: "50%",
              marginLeft: "-15px",
            }}
          />
          <Button
            type="text"
            icon={<RightOutlined />}
            onClick={moveRing("r")}
            className={classes.directionButton}
            style={{
              top: "calc(50% - 18px)",
              right: "-55px",
            }}
          />
          <Button
            type="text"
            icon={<DownOutlined />}
            onClick={moveRing("d")}
            className={classes.directionButton}
            style={{
              left: "50%",
              bottom: "-25px",
              marginLeft: "-15px",
            }}
          />
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={moveRing("l")}
            className={classes.directionButton}
            style={{
              top: "calc(50% - 18px)",
              left: "-55px",
            }}
          />
        </Flex>
      </Flex>

      <Flex direction="column">
        <Flex direction="column" className={classes.sizeDetails}>
          <Typography.Text>Deutsche Größe: {germanRingSize}</Typography.Text>
        </Flex>
        <CustomSlider value={sliderValue} onChange={setSliderValue} />
        <Flex className={classes.nextButtonWrapper} style={{ display: "none" }}>
          <Button
            block
            type="primary"
            className={classes.nextButton}
            onClick={handleNext}
          >
            Wähle diese Ringgröße
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    height: "100vh",
    paddingTop: "2.5rem",
    overflow: "hidden",
    position: "relative",
  },
  goBackBtn: {
    fontSize: 12,
    fontWeight: 600,
    border: "none",
    height: "auto",
    padding: "0 2.5rem !important",
    lineHeight: "16px",

    "& .anticon": {
      fontSize: 10,
      marginRight: "0.25rem",
      textTransform: "uppercase",
    },
  },
  closeBtn: {
    padding: "0 0.5rem !important",
    margin: "0 2.5rem !important",
  },
  stepContent: {
    marginTop: "1.25rem",
    // marginTop: "10rem",
  },
  instructionText: {
    paddingTop: "0.5rem",
    // marginBottom: "8.125rem",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",

    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 300,
  },
  ringWrapper: {
    height: 190,
    width: "100%",
    position: "relative",
    padding: "0 1.25rem",
  },
  ring: {
    position: "absolute",
    left: "calc(50% - 58px)",
    top: "calc(50% - 58px)",
    height: 116,
    width: 116,
    background: "#fff",
    border: "4px solid #000",
    borderRadius: "99%",
    zIndex: 5,
    transform: "scale(1)",
    userSelect: "none",
    boxShadow: "none",
  },
  ringSize: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    userSelect: "none",
    fontSize: 18,
  },
  arrowsWrapper: {
    width: 190,
    height: 190,
    left: "50%",
    position: "absolute",

    marginLeft: "-95px",
  },
  directionButton: {
    position: "absolute",
    padding: "0.25rem 0.5rem",
    "& .anticon": {},
  },

  sizeDetails: {
    padding: [0, "2.5rem"],

    // marginTop: "7rem",
    textAlign: "center",
    "& .ant-typography": {
      fontSize: "1.1rem",
      lineHeight: "1.35rem",
      fontWeight: 300,
    },
  },
  nextButtonWrapper: {
    width: "100%",
    padding: "0 2.5rem",
  },
  nextButton: {
    height: 40,
    border: "none",
    marginTop: "0.75rem",
    marginBottom: "1rem",
    "& > span": {
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
}));
