import { createUseStyles } from "react-jss";
import { Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Slider, { SliderSingleProps } from "antd/es/slider";
import Flex from "./Flex";

export default function CustomSlider({ ...props }: SliderSingleProps) {
  const classes = useStyle();

  return (
    <Flex className={classes.sliderWrapper}>
      <Button
        type="text"
        icon={<MinusOutlined />}
        className={classes.sliderBtns}
        // @ts-ignore
        onClick={() => props.onChange(props.value - 1)}
      />
      <Slider
        {...props}
        tooltip={{ open: false }}
        className={classes.slider}
        trackStyle={{ display: "none" }}
        railStyle={{
          background: "#505050",
          height: 2,
        }}
      />
      <Button
        type="text"
        icon={<PlusOutlined />}
        className={classes.sliderBtns}
        // @ts-ignore
        onClick={() => props.onChange(props.value + 1)}
      />
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  sliderWrapper: {
    width: "100vw",
    height: 40,
    zIndex: 100,
  },
  slider: {
    flex: 1,
    "& .ant-slider-handle": {
      "&::after": {
        transform: "scale(2.7)",
        boxShadow: "0 0 0 0.3px #262626 !important",
        height: "10px !important",
        width: "10px !important",
      },
    },
  },
  sliderBtns: {
    minWidth: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
