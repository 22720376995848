import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Button, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Flex from "components/Flex";

interface Step5Props {
  onBack: () => void;
}

export default function Step5({ onBack }: Step5Props) {
  const classes = useStyle();
  const [ringSize, setRingSize] = useState("");

  const handleReturnToLastPage = () => {
    let redirectUrl = new URLSearchParams(window.location.search).get(
      "redirect"
    );

    if (!!redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      onBack();
    }
  };

  useEffect(() => {
    let size = localStorage.getItem("ringSize");
    if (!!size) {
      setRingSize(size);
    }
  }, [localStorage.getItem("ringSize")]);

  return (
    <Flex
      block
      align="center"
      justify="space-between"
      direction="column"
      className={classes.stepWrapper}
    >
      <Flex block justify="space-between">
        <Button
          icon={<LeftOutlined />}
          type="text"
          className={classes.goBackBtn}
          onClick={onBack}
        >
          Zurück
        </Button>
      </Flex>

      <Typography.Text className={classes.ringSizeText}>
        Deine Größe: {ringSize}
      </Typography.Text>

      <Typography.Text className={classes.extraText}>
        Du verlässt den Ringgrößenmesser. Was willst du als nächstes tun?
      </Typography.Text>

      <Flex block direction="column" className={classes.buttonsWrapper}>
        <Button
          className={classes.stepButton}
          type="primary"
          onClick={handleReturnToLastPage}
        >
          Zurück zu meinem Ring
        </Button>
        <Button
          type="primary"
          href="https://shop.edelsteinfasser-kumar.de/collections/alle-produkte"
          className={classes.stepButton}
        >
          All unseren Schmuck entdecken
        </Button>
        <Button
          type="primary"
          href="https://shop.edelsteinfasser-kumar.de/"
          className={classes.stepButton}
        >
          Besuche unseren Shop
        </Button>
      </Flex>
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    height: "100vh",
    width: "100%",
    paddingTop: "2.5rem",
    overflowX: "hidden",
    position: "relative",
  },
  goBackBtn: {
    fontSize: 12,
    fontWeight: 600,
    border: "none",
    height: "auto",
    padding: "0 2.5rem !important",
    lineHeight: "16px",

    "& .anticon": {
      fontSize: 10,
      marginRight: "0.25rem",
      textTransform: "uppercase",
    },
  },
  closeBtn: {
    padding: "0 0.5rem !important",
    margin: "0 2.5rem !important",
  },
  ringSizeText: {
    fontSize: "1.4rem",
    lineHeight: "1.75rem",
  },
  extraText: {
    padding: [0, "2.5rem"],
    textAlign: "center",
    fontSize: "1.15rem",
    lineHeight: "1.5rem",
    fontWeight: 300,
  },
  stepButton: {
    height: 40,
    border: "none",
    marginTop: "0.5rem",
    borderRadius: "0 !important",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > span": {
      fontWeight: 600,
    },
  },
  buttonsWrapper: {
    padding: [0, "2.5rem"],
    marginBottom: "2.5rem",
  },
}));
