import { createUseStyles } from "react-jss";
import { Button, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Flex from "components/Flex";
import DiamonRingIcon from "../../assets/diamond-ring.webp";
import StringIcon from "../../assets/string.webp";

interface Step3Props {
  onBack: () => void;
  onNext: () => void;
  onChangeHaveRing: (val: boolean) => void;
}

export default function Step3({ onBack, onChangeHaveRing }: Step3Props) {
  const classes = useStyle();

  return (
    <div className={classes.stepWrapper}>
      <Button
        icon={<LeftOutlined />}
        type="text"
        className={classes.goBackBtn}
        onClick={onBack}
      >
        Zurück
      </Button>

      <Flex justify="center" direction="column" className={classes.stepContent}>
        <Typography.Text className={classes.helpText}>
          Wähle eine der beiden Optionen unten aus.
        </Typography.Text>

        <Button
          className={classes.stepButton}
          type="primary"
          onClick={() => onChangeHaveRing(true)}
        >
          <img
            src={DiamonRingIcon}
            className={classes.stepButtonIcon}
            alt="diamon-ring"
          />
          Ich habe einen Ring zum Messen.
        </Button>

        <Button
          className={classes.stepButton}
          type="primary"
          onClick={() => onChangeHaveRing(false)}
        >
          <img
            src={StringIcon}
            className={classes.stepButtonIcon}
            alt="diamon-ring"
          />
          Ich habe keinen Ring zum Messen.
        </Button>
      </Flex>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    marginTop: "2.5rem",
    overflowX: "hidden",
    position: "relative",
  },
  goBackBtn: {
    fontSize: 12,
    fontWeight: 600,
    border: "none",
    height: "auto",
    padding: "0 2.5rem !important",
    lineHeight: "16px",

    "& .anticon": {
      fontSize: 10,
      marginRight: "0.25rem",
      textTransform: "uppercase",
    },
  },
  stepContent: {
    marginTop: "10rem",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  },
  helpText: {
    paddingTop: "0.5rem",
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 300,
  },
  stepButton: {
    height: 40,
    border: "none",
    marginTop: "2.5rem",
    borderRadius: "0 !important",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& > span": {
      fontWeight: 600,
    },
  },
  stepButtonIcon: {
    height: 20,
    width: 20,
    objectFit: "contain",
    marginRight: "0.5rem",
  },
}));
