import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import CustomSlider from "components/Slider";
import Flex from "components/Flex";
import CardImage from "../../assets/creditcard-mm.png";

interface Step2Props {
  onBack: () => void;
  onNext: () => void;
}

export default function Step2({ onBack, onNext }: Step2Props) {
  const classes = useStyle();
  const debitCard = useRef<HTMLDivElement>(null);
  const [sliderValue, setSliderValue] = useState(50);
  const [scale, setScale] = useState(1);
  const [cardRight, setCardRight] = useState(-320);

  const handleNextClick = () => {
    if (!!debitCard.current) {
      let pixelHeightOfCard =
        debitCard.current.getBoundingClientRect().bottom -
        debitCard.current.getBoundingClientRect().top;

      let pixelsToMmRatio = "" + pixelHeightOfCard / 54;
      localStorage.setItem("px2mm", pixelsToMmRatio);
    }

    onNext();
  };

  useEffect(() => {
    setScale(0.6 + 0.008 * sliderValue);
    setCardRight(-320 - 2.4 * sliderValue);
  }, [sliderValue]);

  return (
    <Flex
      direction="column"
      justify="space-between"
      className={classes.stepWrapper}
    >
      <Flex>
        <Button
          icon={<LeftOutlined />}
          type="text"
          className={classes.goBackBtn}
          onClick={onBack}
        >
          Zurück
        </Button>
      </Flex>

      <div />

      <Flex direction="column" className={classes.stepDetailsWrapper}>
        <Typography.Text strong className={classes.stepText}>
          Schritt 2
        </Typography.Text>
        <Typography.Text className={classes.stepDetails}>
          Lege einfach eine Bankkarte oder Kreditkarte auf das Kartenbild, das
          du auf dem Bildschirm siehst. Passe die schwarze Umrandung an, indem
          du den Kreis am unteren Bildschirmrand nach links oder rechts
          schiebst. Die schwarze Linie sollte die Karte umrahmen, aber noch
          sichtbar sein.
          <br />
          <p style={{ marginTop: "0.5rem" }}>Klicke dann auf "Weiter".</p>
        </Typography.Text>
      </Flex>

      <Flex className={classes.cardImageWrapper}>
        <div
          ref={debitCard}
          style={{
            backgroundImage: `url('${CardImage}')`,
            transform: `scale(${scale})`,
            right: `${cardRight}px`,
          }}
          className={classes.cardImage}
        />
      </Flex>

      <Flex direction="column">
        <CustomSlider value={sliderValue} onChange={setSliderValue} />
        <Flex className={classes.nextButtonWrapper} style={{ display: "none" }}>
          <Button
            block
            type="primary"
            className={classes.nextButton}
            onClick={handleNextClick}
          >
            Weiter
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    height: "100vh",
    paddingTop: "2.5rem",
    overflowX: "hidden",
    position: "relative",
  },
  goBackBtn: {
    fontSize: 12,
    fontWeight: 600,
    border: "none",
    height: "auto",
    padding: "0 2.5rem !important",
    lineHeight: "16px",

    "& .anticon": {
      fontSize: 10,
      marginRight: "0.25rem",
      textTransform: "uppercase",
    },
  },
  stepDetailsWrapper: {
    width: "50%",
    paddingLeft: "1.25rem",
  },
  stepText: {
    // marginTop: "6rem",
    paddingTop: "0.25rem",
    marginBottom: "0.5rem",
  },
  stepDetails: {
    paddingTop: "0.25rem",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 300,
  },
  cardImageWrapper: {
    position: "relative",
    width: "100%",
    // height: "calc(100vh - 540px)",
  },
  cardImage: {
    display: "block",
    position: "absolute",
    bottom: "40%",
    right: "-450px",
    width: 600,
    height: 400,
    border: "4px solid #000",
    borderRadius: 15,
    transform: "scale(1)",
    userSelect: "none",
    backgroundColor: "#BFD7ED",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  nextButtonWrapper: {
    width: "100%",
    padding: "0 2.5rem",
  },
  nextButton: {
    height: 40,
    border: "none",
    marginTop: "0.75rem",
    marginBottom: "1rem",
    "& > span": {
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
}));
