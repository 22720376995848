import { createUseStyles } from "react-jss";
import { Carousel } from "antd";
import { useRef, useState } from "react";
import { CarouselRef } from "antd/es/carousel";
import Step1 from "components/Steps/Step1";
import Step2 from "components/Steps/Step2";
import Step3 from "components/Steps/Step3";
import Step4Type1 from "components/Steps/Step4-1";
import Step4Type2 from "components/Steps/Step4-2";
import Step5 from "components/Steps/Step5";
import QRPage from "./QRPage";

export default function HomePage() {
  const classes = useStyle();
  const CarouselRef = useRef<CarouselRef>(null);
  const [activeStep, setActiveStep] = useState(1);
  const [haveRing, setHaveRing] = useState(true);

  const handleNext = () => {
    CarouselRef.current?.next();
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {
    CarouselRef.current?.prev();
    setActiveStep(activeStep - 1);
  };

  const handleChangeHaveRing = (val: boolean) => {
    setHaveRing(val);
    handleNext();
  };

  if (typeof window?.orientation === "undefined") {
    return <QRPage />;
  }

  return (
    <div className={classes.homePage}>
      <Carousel
        dots={false}
        swipe={false}
        accessibility={false}
        swipeToSlide={false}
        ref={CarouselRef}
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Step1 onNext={handleNext} />
        <Step2 onBack={handlePrev} onNext={handleNext} />
        <Step3
          onBack={handlePrev}
          onNext={handleNext}
          onChangeHaveRing={handleChangeHaveRing}
        />
        {haveRing ? (
          <Step4Type1 onBack={handlePrev} onNext={handleNext} />
        ) : (
          <Step4Type2 onBack={handlePrev} onNext={handleNext} />
        )}
        <Step5 onBack={handlePrev} />
      </Carousel>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  homePage: {},
  nextButtonWrapper: {
    width: "100%",
    padding: "0 2.5rem",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
  nextButton: {
    height: 40,
    border: "none",
    marginBottom: "1rem",
    "& > span": {
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
}));
