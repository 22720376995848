import { createUseStyles } from "react-jss";
import { Button, Typography } from "antd";
import Flex from "components/Flex";
import RingImage from "../../assets/ring.png";

interface Step1Props {
  onNext: () => void;
}

export default function Step1({ onNext }: Step1Props) {
  const classes = useStyle();

  return (
    <Flex
      justify="space-between"
      align="center"
      direction="column"
      className={classes.stepWrapper}
    >
      <div />
      <Flex
        justify="center"
        align="center"
        direction="column"
        className={classes.stepContent}
      >
        <Typography.Text strong className={classes.stepText}>
          Schritt 1
        </Typography.Text>
        <Typography.Text className={classes.stepDetails}>
          Lege dein Telefon auf eine ebene Fläche. Stelle dich hin und betrachte
          dein Telefon aus der Vogelperspektive.
        </Typography.Text>

        <img src={RingImage} alt="ring" className={classes.ringImage} />
      </Flex>

      <Flex className={classes.nextButtonWrapper} style={{ display: "none" }}>
        <Button
          block
          type="primary"
          className={classes.nextButton}
          onClick={onNext}
        >
          Weiter
        </Button>
      </Flex>
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    height: "100vh",
    "& > .ant-typography": {
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
  },
  stepContent: {
    padding: [20, 40],
  },
  stepText: {
    // marginTop: "5rem",
  },
  stepDetails: {
    marginTop: "1.25rem",
    marginBottom: "2.5rem",

    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 300,
  },
  ringImage: {
    width: 250,
    objectFit: "contain",
  },
  nextButtonWrapper: {
    width: "100%",
    padding: "0 2.5rem",
  },
  nextButton: {
    height: 40,
    border: "none",
    marginBottom: "1rem",
    "& > span": {
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
}));
