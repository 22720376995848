import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import CustomSlider from "components/Slider";
import Flex from "components/Flex";

import ringSizes from "data/ring-sizes.json";

interface Step4Props {
  onBack: () => void;
  onNext: () => void;
}

export default function Step4Type1({ onBack, onNext }: Step4Props) {
  const classes = useStyle();
  const line = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [lineLength, setLineLength] = useState(1);
  const [sliderValue, setSliderValue] = useState(49);
  const [pixelToMmRatio, setPixelToMmRatio] = useState(0);
  const [germanRingSize, setGermanRingSize] = useState("");

  const handleNext = () => {
    localStorage.setItem("ringSize", germanRingSize);
    onNext();
  };

  useEffect(() => {
    let value = localStorage.getItem("px2mm");
    if (!!value) {
      setPixelToMmRatio(+value);
    }
  }, [localStorage.getItem("px2mm")]);

  useEffect(() => {
    if (pixelToMmRatio) {
      const length =
        line!!.current!!.getBoundingClientRect().bottom -
        line!!.current!!.getBoundingClientRect().top;
      const mm = (length / pixelToMmRatio).toFixed(2);

      let diameter = +mm / Math.PI;
      let _germanRingSize =
        ringSizes.sort((a, b) => b.mm - a.mm).find((size) => diameter > size.mm)
          ?.size ?? `Kleiner als 40`;

      setLineLength(+mm);
      setGermanRingSize("" + _germanRingSize);
      setScale(0.6 + 0.008 * sliderValue);
    }
  }, [sliderValue, pixelToMmRatio]);

  return (
    <Flex
      direction="column"
      justify="space-between"
      className={classes.stepWrapper}
    >
      <Flex justify="space-between">
        <Button
          icon={<LeftOutlined />}
          type="text"
          className={classes.goBackBtn}
          onClick={onBack}
        >
          Zurück
        </Button>
      </Flex>

      <div />

      <Flex className={classes.stepContent}>
        <Typography.Text className={classes.instructionsText}>
          Nimm einen Faden oder ein dünnen Streifen Papier und wickle ihn um
          deinen Finger. Markiere die Länge oder schneide den Faden/das Papier
          an der Stelle ab, wo sich beide Enden treffen.
          <br />
          Lege dann den Faden/das Papier auf die schwarze Linie und passe die
          Länge entsprechend an.
        </Typography.Text>
        <Flex className={classes.lineWrapper}>
          <div
            ref={line}
            className={classes.line}
            style={{
              transform: `scale(${scale})`,
            }}
          >
            <div className={classes.lineHeight}>{lineLength}mm</div>
          </div>
        </Flex>
      </Flex>

      <Flex direction="column">
        <Flex direction="column" className={classes.sizeDetails}>
          <Typography.Text>Deutsche Größe: {germanRingSize}</Typography.Text>
        </Flex>
        <CustomSlider value={sliderValue} onChange={setSliderValue} />
        <Flex className={classes.nextButtonWrapper} style={{ display: "none" }}>
          <Button
            block
            type="primary"
            className={classes.nextButton}
            onClick={handleNext}
          >
            Wähle diese Ringgröße
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  stepWrapper: {
    height: "100vh",
    paddingTop: "2.5rem",
    overflow: "hidden",
    position: "relative",
  },
  goBackBtn: {
    fontSize: 12,
    fontWeight: 600,
    border: "none",
    height: "auto",
    padding: "0 2.5rem !important",
    lineHeight: "16px",

    "& .anticon": {
      fontSize: 10,
      marginRight: "0.25rem",
      textTransform: "uppercase",
    },
  },
  closeBtn: {
    padding: "0 0.5rem !important",
    margin: "0 2.5rem !important",
  },

  stepContent: {
    // marginTop: "5rem",
    padding: "0 2.5rem",
  },
  instructionsText: {
    paddingRight: 15,
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontWeight: 300,
  },

  lineWrapper: {
    height: 190,
    position: "relative",
    width: "100%",
  },
  line: {
    position: "absolute",
    left: "calc(50% - 4px)",
    top: "-80%",
    height: 400,
    width: 8,
    zIndex: 5,
    transform: "scale(1)",
    userSelect: "none",
    background: "#BFD7ED",
  },
  lineHeight: {
    position: "absolute",
    top: "45%",
    left: "80%",
    transform: "translate(-50%, -50%)",
    userSelect: "none",
    fontSize: 16,
    fontWeight: 300,
  },

  sizeDetails: {
    padding: [0, "2.5rem"],

    // marginTop: "9.5rem",
    textAlign: "center",
    "& .ant-typography": {
      fontSize: "1.1rem",
      lineHeight: "1.35rem",
      fontWeight: 300,
    },
  },
  nextButtonWrapper: {
    width: "100%",
    padding: "0 2.5rem",
  },
  nextButton: {
    height: 40,
    border: "none",
    marginTop: "0.75rem",
    marginBottom: "1rem",
    "& > span": {
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
}));
